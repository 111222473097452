import { Language } from '@kerberos-compliance/lib-adp-shared/localization/language';

const MINUTES = 30;
const SECONDS = 60;
const MILLISECONDS = 1000;

export class AppConstants {
  public static readonly DefaultLanguage: string = Language.German;

  public static readonly AvailableLanguages = Object.values(Language) as string[];

  public static readonly UserInactiveTimeout = MINUTES * SECONDS * MILLISECONDS;

  public static readonly DisplayDateFormat = 'dd.MM.yyyy';

  public static readonly IsoDateFormat = 'yyyy-MM-dd';

  public static readonly BackendDateFormat = 'dd/MM/yyyy';
}
