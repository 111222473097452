import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { SharedModule } from '@/shared/shared.module';

@Component({
  selector: 'app-connection-status',
  standalone: true,
  imports: [SharedModule, TranslocoModule, MatIconModule],
  templateUrl: './no-connection.component.html',
})
export class NoConnectionComponent {}
