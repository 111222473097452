import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from '../reducers/auth.reducers';

/**
 * Works as a bridge to access the auth property from the store
 */
export const selectAuthFeature = createFeatureSelector<AuthState>(authFeatureKey);

/**
 * Uses the Selector to get the user from the store
 */
export const selectUserProfile = createSelector(selectAuthFeature, (state: AuthState) => state.user);

/**
 * Uses the Selector to get the user from the store
 */
export const selectIsUserLoggedIn = createSelector(selectAuthFeature, (state: AuthState) => !!state.user);

/**
 * Uses the Selector to get the logoutReason from the store
 */
export const selectLogoutReason = createSelector(selectAuthFeature, (state: AuthState) => state.logoutReason);

/**
 * Uses the Selector to get the grants from the store
 */
export const selectOwnGrants = createSelector(selectAuthFeature, (state: AuthState) => state.ownGrants);

export const selectUserLanguage = createSelector(
  selectAuthFeature,
  (state: AuthState) => state.user?.preferredLanguage,
);

export const selectOrganizationId = createSelector(selectAuthFeature, (state: AuthState) => state.organizationId);

export const selectGrantByOrganizationId = createSelector(selectAuthFeature, (state: AuthState) =>
  state?.ownGrants?.find((grant) => grant.organizationId === selectOrganizationId(grant)),
);
