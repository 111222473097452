import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KerberosAuthEffects } from '@/auth/store/effects/kerberos-auth.effects';
import { authFeatureKey, authReducer } from '@/auth/store/reducers/auth.reducers';
import { authTokensReducer, kerberosAuthTokensFeatureKey } from '@/auth/store/reducers/auth-kerberos.reducers';
import { LogoComponent } from '@/shared/components/fragments/logo/logo.component';
import { SharedModule } from '@/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { AuthEffects } from './store/effects/auth.effects';

@NgModule({
  declarations: [LogoutPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature(kerberosAuthTokensFeatureKey, authTokensReducer),
    StoreModule.forFeature(authFeatureKey, authReducer),
    EffectsModule.forFeature([AuthEffects, KerberosAuthEffects]),
    TranslocoModule,
    LogoComponent,
  ],
})
export class AuthModule {}
