import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileType, mimeType } from '../../enums/file-type';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  /**
   * Regex to extract filename from Content-Disposition header
   * Example: Content-Disposition: attachment; filename="example.pdf"
   */
  private static FILE_NAME_REGEX: RegExp = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;

  private static ILLEGAL_FILENAME_CHARS_REGEX = /["%*/:<>?\\|]/g;

  public constructor(private http: HttpClient) {}

  /**
   * Download file from given url and save it with given filename
   * @param url - url of the file to download
   * @param filename - optional filename to save the file with
   * @param fileType - optional file type to convert the blob to the appropriate MIME type
   *   *
   * @notes
   * As per MDN docs, Based on the current implementation, browsers won't actually read the bytestream of a file to determine its media type.
   * It is assumed based on the file extension;
   *
   * @remarks
   * if you want to download a file from the Google bucket, you would typically have the filename with extension in the url and it would be enough to download the file.
   * But if the url does not have the filename with extension, you can pass the fileType and the filename.
   */
  public download(url: string, fileType?: FileType, filename?: string): void {
    this.fetchFile(url).subscribe((response: HttpResponse<Blob> | undefined) => {
      if (!response) {
        return;
      }

      let blob: Blob = response.body as Blob;

      // If a file type is provided, convert the blob to the appropriate MIME type
      blob = this.convertBlob(blob, fileType);

      filename = filename || this.extractFileName(response, url);
      filename = this.sanitizeFileName(filename);
      this.initiateDownload(blob, filename);
    });
  }

  /**
   * Initiates a direct download of a file with the given blob and filename.
   *
   * @param {Blob} blob - The binary large object representing the file data to be downloaded.
   * @param {string} filename - The desired name for the downloaded file.
   * @return {void}
   */
  public downloadFileDirectly(blob: Blob, filename: string): void {
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    const downloadURL = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    URL.revokeObjectURL(downloadURL);
  }

  /**
   * Fetches the MIME type of file by making an HTTP HEAD request.
   *
   * @param {string} url - The URL of the file.
   * @return {Observable<FileType>} An Observable that emits the determined FileType.
   */
  public fetchFileType(url: string): Observable<FileType> {
    return this.http.head(url, { observe: 'response' }).pipe(
      map((response) => {
        const contentType = response.headers.get('content-type');
        switch (contentType) {
          case 'application/pdf': {
            return FileType.Pdf;
          }
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
            return FileType.Xlsx;
          }
          default: {
            return FileType.Pdf;
          }
        }
      }),
      catchError((error) => {
        console.error('Error fetching file type:', error);
        return of(FileType.Pdf); // Default to unknown type on error
      }),
    );
  }

  /**
   * Triggers the download of a CSV file.
   *
   * @param {string} csv - The CSV content as a string.
   * @return {void}
   */
  public triggerDownloadCsv(csv: string): void {
    const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });
    this.initiateDownload(blob, `${new Date().toISOString()}.csv`);
  }

  /**
   * Initiates the download of a given Blob with the specified file name.
   *
   * @param {Blob} blob - The binary large object (Blob) to be downloaded.
   * @param {string} filename - The name with which the Blob should be saved.
   * @return {void} Does not return any value.
   */
  public initiateDownload(blob: Blob, filename: string): void {
    this.openInNewTab(blob, filename);
  }

  /**
   * Extract the filename from the HTTP response headers or URL.
   * @param response - The HTTP response containing the file.
   * @param url - The downloadURL of the file.
   * @returns The extracted or inferred filename.
   */
  public extractFileName(response: HttpResponse<Blob>, url: string): string {
    const DEFAULT_FILENAME = 'download';

    const contentDisposition = response.headers.get('Content-Disposition');

    // If Content-Disposition header is not present, extract filename from URL
    if (!contentDisposition) {
      return url.split('/').pop() || DEFAULT_FILENAME;
    }
    // extract filename from Content-Disposition header
    const match = contentDisposition.match(FileDownloadService.FILE_NAME_REGEX);
    if (match && match.groups && match.groups['filename']) {
      return match.groups['filename'];
    }
    return DEFAULT_FILENAME;
  }

  /**
   * Sanitize the filename by replacing illegal characters.
   * @param fileName - The filename to sanitize.
   * @returns The sanitized filename.
   */
  public sanitizeFileName(fileName: string): string {
    return fileName.replace(FileDownloadService.ILLEGAL_FILENAME_CHARS_REGEX, '-');
  }

  /**
   * Adjusts the filename based on the determined file type.
   * @param {string} fileName - The default filename with extension.
   * @param {FileType} fileType - The determined file type.
   * @return {string} - The adjusted filename.
   */
  public adjustFileName(fileName: string, fileType: FileType): string {
    const extension = this.getFileExtension(fileType);
    const baseFileName = fileName.replace(/\.[^./]+$/, ''); // Remove existing extension
    return `${baseFileName}.${extension}`;
  }

  /**
   * Extracts the filename from the Content-Disposition header.
   *
   * @param {string} contentDisposition - The Content-Disposition header to parse.
   * @return {string} The extracted filename, or an empty string if no filename is found.
   */
  public getFilenameFromContentDisposition(contentDisposition: string): string {
    const matches = FileDownloadService.FILE_NAME_REGEX.exec(contentDisposition);
    if (matches !== null && matches[1]) {
      return matches[1].replaceAll(/["']/g, '');
    }
    return '';
  }

  /**
   * Maps the FileType to a file extension.
   * @param {FileType} fileType - The determined file type.
   * @return {string} - The file extension associated with the file type.
   */
  private getFileExtension(fileType: FileType): string {
    switch (fileType) {
      case FileType.Pdf: {
        return FileType.Pdf;
      }
      case FileType.Xlsx: {
        return FileType.Xlsx;
      }
      default: {
        return 'pdf';
      }
    }
  }

  /**
   * Downloads a file from the given URL and returns an Observable that emits the HTTP response containing the file as a Blob.
   *
   * @param {string} url - The URL from which to fetch the file.
   * @return {Observable<HttpResponse<Blob>>} An Observable that emits the HTTP response containing the file as a Blob.
   */
  private fetchFile(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      take(1),
      catchError((error) => {
        console.error('Error downloading the file:', error);
        return of();
      }),
    );
  }

  /**
   * Converts the given Blob to a new Blob with the specified file type if provided.
   *
   * @param {Blob} blob - The original Blob to be converted.
   * @param {FileType | undefined} fileType - The desired file type to convert the Blob to. If undefined, the original Blob is returned.
   * @return {Blob} - The converted Blob with the specified file type, or the original Blob if no file type is provided.
   */
  private convertBlob(blob: Blob, fileType: FileType | undefined): Blob {
    if (fileType && mimeType[fileType]) {
      blob = new Blob([blob], {
        type: mimeType[fileType],
      });
    }
    return blob;
  }

  /**
   * Opens a new tab with the provided file and assigns the specified filename as the tab's title.
   *
   * @param {Blob} blob - The blob object representing the file to be opened.
   * @param {string} filename - The name to be assigned to the new tab's title.
   * @return {void}
   */
  private openInNewTab(blob: Blob, filename: string): void {
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    const downloadURL = URL.createObjectURL(blob);
    const tabWithFileName = window.open(downloadURL, '_blank');
    if (tabWithFileName) {
      tabWithFileName.document.title = filename;
    }
    this.downloadFileDirectly(blob, filename);
  }
}
