import { HttpClientModule } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { Language } from '@kerberos-compliance/lib-adp-shared/localization/language';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  imports: [HttpClientModule, TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [Language.English, Language.German],
        defaultLang: Language.English,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
