import { User } from '@core/models/user.model';
import { OwnGrant } from '@core/types/grant.types';
import { createAction, props } from '@ngrx/store';

const setUserInfo = createAction('[Auth] Set user data', props<{ user: User }>());

// TODO: this is only observed inside a reducer, but the action itself is never executed
const loginFailure = createAction('[Auth] Login failure', props<{ errorMessage: string }>());

const logout = createAction('[Auth] User Logout', props<{ logoutReason: LogoutReason }>());

// TODO: this action is observed inside auth.effects.ts, but the action itself is never executed
const resetLogoutReason = createAction('[Auth] Reset Logout Reason');

const getGrant = createAction('[Auth] Get Grant', props<{ accessTokenKerberos: string }>());

const getGrantSuccess = createAction('[Auth] Get Grant Success', props<{ grants: OwnGrant[] }>());

const getGrantFailure = createAction('[Auth] Get Grant Failure', props<{ error: Error }>());

const grantLoadingComplete = createAction('[Auth] Grant Loading Complete');

const setSessionGrant = createAction(
  '[Auth] Set session Organization grant for user',
  props<{ organizationId: string }>(),
);

export enum LogoutReason {
  Manual = 'manual',
  Inactivity = 'inactivity',
  SessionExpired = 'sessionExpired',
}

export const AuthActions = {
  logout,
  setUserInfo,
  loginFailure,
  resetLogoutReason,
  getGrant,
  getGrantSuccess,
  getGrantFailure,
  grantLoadingComplete,
  setSessionGrant,
};
