<app-content-wrapper *transloco="let t" [center]="false" [padded]="false" [showSupportBtn]="true" variant="fullScreen">
  <div class="flex flex-col justify-center px-4 pb-4 pt-6 md:pt-27">
    <div class="mx-auto h-screen w-full max-w-3xl">
      <img [src]="imageExpired" alt="contract expired" class="mx-auto w-36 py-16" />
      <h2 class="mb-10">{{ t('contract.expiredContract') }}</h2>

      <ng-container *ngIf="userEmail$ | async as user">
        <ng-container *ngIf="contractInfoRequested$ | async as contractInfo">
          <ng-container *ngIf="contractInfo.contract; else noContract">
            <app-button
              class="w-full"
              variant="primary"
              [disabled]="contractInfo.contract.contractInfoRequested"
              (click)="sendCaseToSalesforce(user.email, contractInfo.contract.contract.accountName || '')"
            >
              {{ t('contract.sendRequest') }}
            </app-button>

            <div
              *ngIf="contractInfo.contract.contractInfoRequested"
              [ngClass]="{ 'slide-up': contractInfo.contract.contractInfoRequested }"
              class="my-6 flex h-20 items-center rounded-xl bg-base-100 shadow-md"
            >
              <div class="flex size-20 items-center justify-center rounded-l-xl bg-success-200">
                <mat-icon svgIcon="cop-success-check" class="size-10"></mat-icon>
              </div>
              <h5 class="mx-3">{{ t('contract.requestSent') }}</h5>
            </div>
          </ng-container>
          <ng-template #noContract>
            <p class="rounded-md bg-red-100 p-4 font-semibold text-red-500">
              Error loading the contract information {{ contractInfo.error }}
            </p>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>
</app-content-wrapper>
