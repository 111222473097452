import { FeaturesAmlDeskWithGrants } from '@core/types/grant.types';

const featureRoutePatterns = [
  {
    pattern: /work-item|communication|data-reference/,
    feature: FeaturesAmlDeskWithGrants.KnowYourCustomer,
  },
  {
    pattern: /location-analysis/,
    feature: FeaturesAmlDeskWithGrants.LocationAnalysis,
  },
  {
    pattern: /.*/g,
    feature: FeaturesAmlDeskWithGrants.RiskAnalysis,
  },
] as const;

export const kerberosConfiguration = {
  MAX_RETRY_ATTEMPTS: 1,
  RETRY_ATTEMPT_HEADER: 'Retry-Attempt',
  UNAUTHORIZED_STATUS: 401,
} as const;

/**
 * This function finds and returns a feature associated with the given URL.
 * It iterates over a set of predefined route patterns and checks if any pattern matches the provided URL.
 * If a match is found, it returns the corresponding feature.
 * If no match is found, it defaults to the `RiskAnalysis` feature.
 *
 * @param {string} url - The URL for which the feature is to be determined.
 * @return {FeaturesAmlDeskWithGrants} The feature associated with the provided URL.
 */
export function getFeatureForUrl(url: string): FeaturesAmlDeskWithGrants {
  const match = featureRoutePatterns.find(({ pattern }) => pattern.test(url));
  return match?.feature ?? FeaturesAmlDeskWithGrants.RiskAnalysis;
}
