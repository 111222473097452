import { FeaturesAmlDeskWithGrants, OwnGrant, RoleDisplayName } from '@core/types/grant.types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '@/auth/store/reducers/auth.reducers';
import { selectOrganizationId, selectOwnGrants } from '@/auth/store/selectors/auth.selectors';
import { AuthStateTokenAndOwnGrant, FeatureRoleMap } from '@/auth/types/auth.types';
import { selectAuthTokens } from './kerberos-auth.selectors';

/**
 * Selects authentication tokens and user grants for a specified feature and required roles.
 *
 * @param {FeaturesAmlDeskWithGrants} [feature] - The feature for which to retrieve relevant grants.
 * The feature maps to specific roles that are considered relevant.
 *
 * @param {RoleDisplayName[]} [requiredRoles] - The list of required role display names.
 * If provided, the function filters grants to include only those whose roles match the provided list.
 *
 * @returns {MemoizedSelector<object, AuthStateTokenAndOwnGrant>} - A selector that retrieves
 * the authentication state tokens and the user's own grant based on the provided feature and required roles.
 */
export const selectAuthTokensAndOwnGrant = (
  feature?: FeaturesAmlDeskWithGrants,
  requiredRoles?: RoleDisplayName[],
): MemoizedSelector<object, AuthStateTokenAndOwnGrant> =>
  createSelector(
    selectAuthTokens,
    selectOwnGrants,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    selectOrganizationId,
    (authStateTokens, ownGrants, selectedOrganizationId): AuthStateTokenAndOwnGrant => {
      let ownGrant: OwnGrant | undefined;
      if (Array.isArray(ownGrants) && ownGrants.length > 0) {
        const relevantRoles = requiredRoles ?? (feature ? FeatureRoleMap[feature] : Object.values(RoleDisplayName));

        ownGrant = ownGrants.find(
          (grant) => grant.organizationId === selectedOrganizationId && relevantRoles.includes(grant.roleDisplayName),
        );

        // First fallback: no organizationId + relevant roles excluding "BasicUser"
        if (!ownGrant) {
          ownGrant = ownGrants.find(
            (grant) =>
              relevantRoles.includes(grant.roleDisplayName) && grant.roleDisplayName !== RoleDisplayName.BasicUser,
          );
        }

        // Final fallback: no organizationId + any relevant role
        if (!ownGrant) {
          ownGrant = ownGrants.find((grant) => relevantRoles.includes(grant.roleDisplayName));
        }
      }

      return {
        authStateTokens,
        ownGrant,
      };
    },
  );

export const selectAuthState = (state: { auth: AuthState }): AuthState => state.auth;

export const selectGrantsLoadingComplete = createSelector(
  selectAuthState,
  (authState: AuthState): boolean => authState.grantLoadingComplete,
);
