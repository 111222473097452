import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { LinkService } from '@/shared/services/link-product-based.service';

@Component({
  selector: 'app-unauthorized',
  template: `
    <ng-container *transloco="let t">
      <div class="flex flex-col justify-center items-center h-screen text-center">
        <h1 class="text-2xl font-bold">{{ t('unauthorized.title') }}</h1>
        <p class="mb-6">{{ t('unauthorized.message') }}</p>
        <a [href]="link$ | async" class="text-2xl text-blue-500 underline uppercase">{{
          t('unauthorized.backToDashboard')
        }}</a>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [TranslocoDirective, AsyncPipe],
})
export class UnauthorizedComponent {
  public link$: Observable<string>;

  constructor(private readonly linkService: LinkService) {
    this.link$ = this.linkService.getLink();
  }
}
