<mat-sidenav-container
  (backdropClick)="toggle(false)"
  [hasBackdrop]="(isLarge$ | async) === false && ((open$ | async) ?? false)"
  autosize
  class="mt-14 bg-base-200 sm:mt-16 lg:mt-auto"
>
  <mat-sidenav
    (closedStart)="toggle()"
    [fixedInViewport]="true"
    [mode]="(isLarge$ | async) ? 'side' : 'over'"
    [ngClass]="{ 'w-62': open$ | async }"
    [opened]="(open$ | async) || ((isLarge$ | async) ?? false)"
    class="fixed mt-14 shadow-main-navigation sm:mt-16 lg:mt-auto"
  >
    <app-logo [mini]="(open$ | async) === false" class="mb-10 ml-3 mt-6 hidden md:block"></app-logo>

    <app-nav
      (itemClicked)="navItemClicked()"
      [disabled]="(isConnected$ | async) === false"
      [iconsOnly]="(open$ | async) === false"
      [ngClass]="{ 'mt-6': (isLarge$ | async) === false }"
      class="mb-10 flex flex-1"
    ></app-nav>
    <div class="flex w-full items-center justify-center px-3 pb-4 lg:justify-between">
      @if ((open$ | async) && versionVisibility) {
        <span class="text-xs">v{{ version }}</span>
      }
      <button
        (click)="toggle(false)"
        class="float-right hidden rounded-lg p-1 text-primary shadow-md outline-none lg:block"
      >
        <mat-icon [inline]="true" aria-label="menu"
          >{{ (open$ | async) === false ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
        </mat-icon>
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <!--    Important: Do not use ngIf as it would destroy the component when the connection is back-->
    <main [ngClass]="{ hidden: (isConnected$ | async) === false }" class="w-full">
      <ng-content></ng-content>
    </main>
    <div [ngClass]="{ hidden: (isConnected$ | async) }">
      <app-connection-status></app-connection-status>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
