@if (showSelect) {
  <ng-container *transloco="let t">
    <div class="mb-4 mr-6 flex flex-col px-2">
      <p class="my-2 font-bold">{{ t('navigation.selectOrganization')}}</p>
      <select class="select select-primary w-full max-w-xs" [title]="selectedOrganization?.organizationDisplayName" (change)="onSelectionChange($event)">
        @for (organization of organizations; track organization) {
          <option value="{{ organization.organizationId }}" [title]="organization.organizationDisplayName">
            {{ organization.organizationDisplayName }}
          </option>
        }
      </select>
    </div>
  </ng-container>
}
