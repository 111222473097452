import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContractExpiredPageComponent } from '@/contract/pages/contract-expired-page/contract-expired-page.component';
import { ContractEffects } from '@/contract/store/effects/contract.effects';
import { contractFeatureKey, contractReducer } from '@/contract/store/reducers/contract.reducer';
import { SharedModule } from '@/shared/shared.module';
import { ContractRoutingModule } from './contract-routing.module';

@NgModule({
  declarations: [ContractExpiredPageComponent],
  imports: [
    CommonModule,
    ContractRoutingModule,
    SharedModule,
    TranslocoModule,
    StoreModule.forFeature(contractFeatureKey, contractReducer),
    EffectsModule.forFeature([ContractEffects]),
  ],
})
export class ContractModule {}
