<mat-toolbar class="fixed top-0 z-10 grid h-14 grid-cols-3 bg-base-100 shadow-md sm:h-16">
  <button (click)="toggle()" class="outline-none" data-cy="openSidenav" mat-icon-button>
    @if ((open$ | async) === false) {
      <mat-icon color="primary">menu</mat-icon>
    } @else {
      <mat-icon color="primary">close</mat-icon>
    }
  </button>
  <app-logo [mini]="true" class="mx-auto"></app-logo>
  <div class="w-4"></div>
</mat-toolbar>
