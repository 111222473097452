<div class="flex min-h-screen flex-col">
  <app-toolbar class="block lg:hidden"></app-toolbar>
  <div class="grow">
    <app-navbar-container class="h-auto w-full">
      <router-outlet></router-outlet>
    </app-navbar-container>
  </div>
  <app-footer
    [ngClass]="{
      'md:ml-62': marginAlignmentWhenOpenDesktop$ | async,
      'md:ml-124': extraMarginWhenTaskNavigationBarIsOpen$ | async,
      'bg-base-200': extraPadding$ | async
    }"
  ></app-footer>
</div>
