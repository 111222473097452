<div class="flex h-full flex-col">
  <div
    [ngClass]="{
      'mx-auto': center,
      'max-w-xs': variant === 'small',
      'shadow-none md:max-w-full container': variant === 'fullScreen',
      'shadow-none md:max-w-screen-md container': variant === 'medium',
      'shadow-none md:max-w-screen-lg container': variant === 'large',
      'shadow-none md:max-w-screen-xl container': variant === 'xlarge',
      'px-4 pt-6': padded
    }"
    class="mb-4 max-w-full grow"
    id="container-wrap"
  >
    <ng-content></ng-content>
  </div>
</div>

<ng-container *ngIf="showSupportBtn">
  <app-support-floating-button
    *transloco="let t"
    phoneDescription="{{ t('navBarContainer.phoneDescription') }}"
  ></app-support-floating-button>
</ng-container>
