import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwnGrant } from '@core/types/grant.types';
import { TranslocoDirective } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-organization-select',
  standalone: true,
  imports: [AsyncPipe, TranslocoDirective],
  templateUrl: './organization-select.component.html',
})
export class OrganizationSelectComponent implements OnInit {
  @Input() public ownGrant$!: Observable<OwnGrant[] | undefined>;

  @Output() public selectionChange = new EventEmitter<string>();

  public organizations: OwnGrant[] = [];

  public showSelect: boolean = false;

  public selectedOrganizationId?: string;

  public selectedOrganization?: OwnGrant;

  public ngOnInit(): void {
    this.ownGrant$
      .pipe(
        map((grants) => {
          const grantsWithOrgId = (grants || []).filter((grant) => !!grant.organizationId);
          const organizationGrantMap = new Map<string, OwnGrant>();

          for (const grant of grantsWithOrgId) {
            if (!organizationGrantMap.has(grant.organizationId!)) {
              organizationGrantMap.set(grant.organizationId!, grant);
            }
          }

          this.organizations = [...organizationGrantMap.values()];
          this.showSelect = this.organizations.length > 1;

          if (this.organizations.length > 0) {
            this.selectedOrganizationId = this.organizations[0].organizationId!;
            this.selectedOrganization = this.organizations[0];
          }
        }),
      )
      .subscribe();
  }

  /**
   * Handles changes in the selection of the organization dropdown.
   *
   * @param {Event} event - The event object triggered by the selection change.
   * @return {void} This method does not return a value.
   */
  public onSelectionChange(event: Event): void {
    event.preventDefault();
    const selectedOrganizationId = (event.target as HTMLSelectElement).value as unknown as string;
    this.selectionChange.emit(selectedOrganizationId);
    this.selectedOrganizationId = selectedOrganizationId;
    this.selectedOrganization = this.organizations.find(
      (organization) => organization.organizationId === selectedOrganizationId,
    );
  }
}
