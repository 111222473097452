import { Component } from '@angular/core';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [TranslocoModule],
})
export class FooterComponent {
  constructor(private readonly cookieConsent: CookieConsentService) {}

  showCookieConsentSettingsDialog(): void {
    this.cookieConsent.showSettingsDialog();
  }
}
