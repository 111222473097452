<div *transloco="let t" [ngClass]="notification.type" class="flex w-full items-center rounded-lg p-2 text-base shadow-md">
  @if (notification.icon) {
    <button class="icon" mat-icon-button>
      <mat-icon class="icon">{{ notification.icon }}</mat-icon>
    </button>
  }

  <span> {{ t(notification.messageKey) }}</span>

  <!-- EMPTY SPACE -->
  <div class="grow"></div>

  <!--  ACTION BUTTON-->
  @if ((notification.actionLabel || notification.actionIcon) && notification.actionCallback) {
    <button
      (click)="notification.actionCallback()"
      mat-button
    >
      @if (notification.actionIcon) {
        <mat-icon>{{ notification.actionIcon }}</mat-icon>
      }
      {{ notification.actionLabel }}
    </button>
  }
</div>
