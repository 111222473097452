<li
  (click)="onItemClicked()"
  [ngClass]="{
    'w-61.75': !iconOnly && icon,
    'w-full h-14 justify-center mb-3 bg-accent-content rounded-lg': variant === 'secondaryNavMobile'
  }"
  [routerLinkActive]="routePath && !disabled ? 'nav-item-active' : ''"
  [routerLink]="disabled ? undefined : routePath"
  class="text-sm outline-none focus-visible:rounded-md focus-visible:border-2 focus-visible:border-secondary"
>
  <a
    [appRippleDisabled]="disabled"
    [attr.data-cy]="['data-cy']"
    [ngClass]="{
      'hover:bg-blue-hover active:text-neutral': !disabled,
      'hover:bg-transparent cursor-default': disabled,
      'py-2': variant === 'secondaryNav',
      'hover:bg-accent-content focus:bg-accent-content hover:rounded-lg': variant === 'secondaryNavMobile',
      'text-primary ': variant === 'logout' && !disabled,
      'justify-center': variant === 'logout',
      'pl-0 ': variant === 'secondaryNavMobile' || variant === 'secondaryNav'
    }"
    appRipple
    class="relative border-transparent font-medium leading-4.5 outline-none focus:bg-blue-hover"
  >
    @if (icon) {
      <mat-icon
        [ngClass]="{
          'opacity-40': disabled
        }"
        [svgIcon]="icon"
        class="-ml-1 scale-125"
      ></mat-icon>
    }
    @if ((!iconOnly && icon) || textOnly) {
      <div
        [ngClass]="{
          'text-brand': variant === 'secondaryNavMobile' && !disabled,
          'w-auto': variant !== 'logout',
          'text-gray-600': disabled
        }"
        class="ml-3 flex h-8 items-center"
      >
        <ng-content></ng-content>
      </div>
    }
    @if (iconPosition === 'right' && variant === 'secondaryNavMobile') {
      <mat-icon
        [ngClass]="{
          'opacity-40': disabled
        }"
        class="absolute right-2 size-8"
        svgIcon="cop-single-arrow-right"
      ></mat-icon>
    }
  </a>
</li>
