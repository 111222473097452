import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { amlDeskRoutes } from '@core/route-map';
import { AppConstants } from '@src/app/app.constants';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InactivityService implements OnDestroy {
  private lastActivityTime: number = Date.now();

  private checkIntervalId?: number = undefined;

  private activityEventsSubscription: Subscription | undefined = undefined;

  private readonly idleTime = AppConstants.UserInactiveTimeout;

  private readonly checkInterval = 1000;

  constructor(
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {}

  startTracking(): void {
    // Record activity on user events
    const activityEvents$ = fromEvent(document, 'click').pipe(filter(() => !document.hidden));

    this.activityEventsSubscription = activityEvents$.subscribe(() => {
      this.lastActivityTime = Date.now();
    });

    // Use NgZone to prevent Angular's change detection from running on every interval tick
    this.ngZone.runOutsideAngular(() => {
      this.checkIntervalId = window.setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - this.lastActivityTime >= this.idleTime) {
          this.ngZone.run(() => {
            this.stopTracking();
            void this.router.navigate([amlDeskRoutes.logout]);
          });
        }
      }, this.checkInterval);
    });

    // Handle page visibility change
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  stopTracking(): void {
    if (this.activityEventsSubscription) {
      this.activityEventsSubscription.unsubscribe();
      this.activityEventsSubscription = undefined;
    }
    if (this.checkIntervalId) {
      clearInterval(this.checkIntervalId);
      this.checkIntervalId = undefined;
    }
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  ngOnDestroy(): void {
    this.stopTracking();
  }

  private handleVisibilityChange = (): void => {
    if (!document.hidden) {
      this.lastActivityTime = Date.now();
    }
  };
}
