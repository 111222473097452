import { Injectable } from '@angular/core';
import { amlDeskRoutes } from '@core/route-map';
import { OwnGrant, RoleDisplayName } from '@core/types/grant.types';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { KYCRoutes } from '@kyc/route-map-kyc';
import { WorkItemType } from '@kyc/types/kyc.types';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectOwnGrants } from '@/auth/store/selectors/auth.selectors';
import { LocationAnalysisRoutes } from '@/location-analysis/route-map-la';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(
    private readonly kycItemTypeCacheService: KycItemTypeCacheService,
    private readonly store: Store,
  ) {}

  /**
   * Returns an observable of the link based on work item types.
   * @return {Observable<string>}
   */
  public getLink(): Observable<string> {
    const workItemTypes$ = this.kycItemTypeCacheService.workItemTypes$;
    const ownGrants$ = this.store.select(selectOwnGrants);

    return combineLatest([workItemTypes$, ownGrants$]).pipe(
      map(([workItemTypes, ownGrants]) => this.determineLink(workItemTypes, ownGrants ?? [])),
    );
  }

  /**
   * Determines the link based on work item types.
   * @param {WorkItemType[] | null} workItemTypes
   * @return {string}
   */
  private determineLink(workItemTypes: WorkItemType[] | null, ownGrants: OwnGrant[]): string {
    const hasWorkItemUserGrant = ownGrants.some((grant) => grant.roleDisplayName === RoleDisplayName.WorkItemUser);
    const hasLocationAnalysisGrant = ownGrants.some(
      (grant) => grant.roleDisplayName === RoleDisplayName.LocationAnalysisUser,
    );

    if (hasWorkItemUserGrant) {
      const isDDWorkItemType = workItemTypes
        ? workItemTypes.some((item) => item.referenceName?.includes('ddt-') || item.referenceName?.includes('kyc-'))
        : false;

      return isDDWorkItemType ? `/${amlDeskRoutes.kyc}/${KYCRoutes.introduction}` : `/${amlDeskRoutes.dashboard}`;
    } else if (hasLocationAnalysisGrant) {
      return `/${amlDeskRoutes.locationAnalysis}/${LocationAnalysisRoutes.introduction}`;
    } else {
      return `/${amlDeskRoutes.dashboard}`;
    }
  }
}
